<script>
  import { get } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import CheckoutShipping from '@/views/CheckoutShipping'
  import CheckoutBilling from '@/views/CheckoutBilling'
  import CheckoutBreakdown from '@/components/checkout/CheckoutBreakdown'
  import CheckoutErrorModal from '@/components/checkout/CheckoutErrorModal'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'
  import screen from '@/helpers/screen'
  import { events } from '@/helpers/gtm'

  const guardMixin = createGuardMixin(
    async ({ store, route, redirect }) => {
      if ((await store.dispatch('account/loggedInAsync')) === false) {
        return redirect({ name: 'AccountLogin' })
      }
      if (route.path.includes('marketplace')) {
        await store.dispatch('products/ensureFresh')
      } else if (route.path.includes('essentials')) {
        await store.dispatch('essentials/ensureFresh')
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      CheckoutShipping,
      CheckoutBilling,
      CheckoutBreakdown,
      CheckoutErrorModal,
      OrderSummary,
    },
    mixins: [guardMixin],
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials'].includes(value),
      },
    },
    computed: {
      ...get(['route']),
      ...get('account', ['loggedIn']),
      errors() {
        return this.$store.get(`${this.cart}/errors`)
      },
      soldOutLineItems() {
        return this.$store.get(`${this.cart}/soldOutLineItems`)
      },
      isCartEmpty() {
        return this.$store.get(`${this.cart}/isCartEmpty`)
      },
      successfulOrder() {
        return this.$store.get(`${this.cart}/successfulOrder`)
      },
      // TODO: can place order only on billing page
      cart() {
        return `${this.storeType}Cart` // marketplaceCart, essentialsCart
      },
      isSummaryView() {
        return this.route.name === 'CheckoutSummary'
      },
      mobile() {
        return !screen().md
      },
      backToCartUrl() {
        // views start with a capital letter, stores with lowercase
        return `${this.cart.charAt(0).toUpperCase() + this.cart.slice(1)}`
      },
    },
    created() {
      this.initCart()

      events.log({
        name: 'started checkout',
        data: { storeType: this.storeType },
      })
    },
    methods: {
      initCart() {
        this.$store.dispatch(`${this.cart}/initCart`)
      },
      clearErrors() {
        this.$store.dispatch(`${this.cart}/clearErrors`)
      },
      async placeOrderHandler() {
        await this.$store.dispatch(`${this.cart}/placeOrder`)
        if (this.successfulOrder) {
          this.$navigate({ name: 'CheckoutConfirmation' })
        }
      },
    },
  }
</script>

<template>
  <div :class="`pt-4 pb-8 md:py-10 bg-dawn-lt3 ${isSummaryView ? '' : 'min-h-screen'}`">
    <BaseContainer max-width="2xl">
      <Portal to="modal">
        <CheckoutErrorModal
          v-if="errors"
          :store-type="storeType"
          :errors="errors"
          :sold-out-line-items="soldOutLineItems"
          :is-cart-empty="isCartEmpty"
          :back-to-cart-url="backToCartUrl"
          @placeOrder="placeOrderHandler"
          @clearErrors="clearErrors"
        />
      </Portal>
      <div class="layout md:mt-8">
        <div>
          <template v-if="mobile">
            <BaseTransitionView>
              <RouterView />
            </BaseTransitionView>
          </template>

          <template v-else>
            <div class="-mt-4 mb-8 flex items-center">
              <div class="mr-3 w-11 flex-shrink-0">
                <BaseIcon>
                  <IconBoxIllustration />
                </BaseIcon>
              </div>
              <div class="flex-1 flex justify-between items-baseline">
                <div class="font-body">
                  <BaseHeading size="h5" tag="h1">Checkout</BaseHeading>
                </div>
                <BaseLinkStyled
                  :to="{ name: backToCartUrl }"
                  color="black"
                  font-size="xs"
                  icon-position="left"
                  :icon-size="3"
                >
                  <template v-slot:icon>
                    <IconArrowLeft />
                  </template>
                  <template v-slot>Return to cart</template>
                </BaseLinkStyled>
              </div>
            </div>
            <CheckoutShipping :store-type="storeType" />
            <BaseDivider class="mt-8 mb-6" />
            <CheckoutBilling :store-type="storeType" />
            <BaseDivider class="mt-8" />
          </template>
        </div>

        <template v-if="!mobile">
          <div />
          <div>
            <CheckoutBreakdown :cart="cart" @placeOrder="placeOrderHandler">
              <OrderSummary :type="storeType" view="checkout" />
            </CheckoutBreakdown>
          </div>
        </template>
      </div>
    </BaseContainer>
  </div>
</template>

<style lang="postcss" scoped>
  .layout {
    @apply grid;

    @screen md {
      grid-template-columns: 628fr 76fr theme('spacing.82');
    }
  }
</style>
