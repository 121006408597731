<script>
  import { get } from 'vuex-pathify'
  import CheckoutShippingEdit from '@/views/CheckoutShippingEdit'
  import CheckoutShippingAdd from '@/views/CheckoutShippingAdd'
  import screen from '@/helpers/screen'

  export default {
    components: {
      CheckoutShippingEdit,
      CheckoutShippingAdd,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials'].includes(value),
      },
    },
    computed: {
      ...get(['route']),
      ...get('account', ['addresses', 'defaultAddressId']),

      selectedShippingAddress() {
        return this.$store.get(`${this.cart}/selectedShippingAddress`)
      },
      cart() {
        return `${this.storeType}Cart` // marketplaceCart, essentialsCart
      },
      selectedShippingAddressId: {
        get() {
          return this.$store.get(`${this.cart}/selectedShippingAddressId`)
        },
        set(value) {
          this.$store.set(`${this.cart}/selectedShippingAddressId`, value)
        },
      },

      isShippingView() {
        return this.route.name.startsWith('CheckoutShipping')
      },
      isSummaryView() {
        return this.route.name === 'CheckoutSummary'
      },
      mobile() {
        return !screen().md
      },
      backToCartUrl() {
        // views start with a capital letter, stores with lowercase
        return { name: this.cart.charAt(0).toUpperCase() + this.cart.slice(1) }
      },
    },
    watch: {
      selectedShippingAddress() {
        this.$store.dispatch(`${this.cart}/reviewOrder`)
      },
    },
    created() {
      if (!this.selectedShippingAddressId) {
        this.selectedShippingAddressId = this.defaultAddressId
      }
    },
    methods: {
      async closeShipping() {
        this.$navigate({ name: 'CheckoutBilling' })
      },
    },
  }
</script>

<template>
  <div>
    <BaseTransitionView>
      <CheckoutShippingEdit v-if="mobile && route.name === 'CheckoutShippingEdit'" />
      <CheckoutShippingAdd
        v-else-if="mobile && route.name === 'CheckoutShippingAdd'"
        :store-type="storeType"
      />

      <div
        v-else
        :class="`flex ${
          isShippingView || (mobile && route.name === 'CheckoutSummary')
            ? 'flex-col'
            : 'justify-between items-baseline'
        }`"
      >
        <div :class="`mb-3 flex justify-between items-baseline flex-shrink-0`">
          <h2 class="text-lg font-semibold">
            <div v-if="!mobile" :class="`${!isShippingView ? 'md:w-36 lg:w-58' : 'md:w-68'} pl-6`">
              <span class="absolute left-0 top-0">1</span>
              <span>Shipping address</span>
            </div>
            <template v-else>
              <template v-if="isSummaryView">Shipping address</template>
              <template v-else>Confirm shipping address</template>
            </template>
          </h2>
          <BaseLinkStyled v-if="!mobile && isShippingView" font-size="xs" @click="closeShipping">
            Close
          </BaseLinkStyled>
        </div>

        <div v-if="!isShippingView" class="md:mr-auto pl-5 md:pl-0 text-sm">
          <BaseAddress v-if="selectedShippingAddressId" :address="selectedShippingAddress" />
        </div>

        <div v-else>
          <BaseButton v-if="mobile" class="mb-2" @click="closeShipping"> Continue </BaseButton>

          <div class="md:ml-6 border border-gray-active bg-dawn-lt3">
            <BaseRadioGroup v-model="selectedShippingAddressId">
              <div
                v-for="(address, addressIndex) in addresses"
                :key="address.id"
                :class="`py-6 px-4 md:px-8 ${
                  addressIndex < addresses.length - 1 ? 'border-b border-gray-active' : ''
                }`"
              >
                <div class="flex flex-col md:flex-row md:justify-between items-start text-sm">
                  <BaseRadio :value="address.id" size="md">
                    <BaseAddress :address="address" />
                  </BaseRadio>
                  <BaseLinkStyled
                    :to="{ name: 'CheckoutShippingEdit', params: { addressId: address.id } }"
                    font-size="xs"
                    class="mt-3 md:mt-0 ml-9 flex-shrink-0"
                    data-cy="edit-address"
                  >
                    Edit address
                  </BaseLinkStyled>
                </div>
              </div>
            </BaseRadioGroup>

            <template v-if="!mobile">
              <div class="border-t border-gray-active">
                <BaseLink
                  :to="{ name: 'CheckoutShippingAdd' }"
                  class="z-10 flex items-center px-9 py-5 bg-dawn-lt3 text-green hover:text-green-darker active:text-green-darkest font-semibold text-sm transition duration-200"
                  data-cy="add-address"
                >
                  <BaseIcon :size="4" class="mr-4">
                    <IconPlus />
                  </BaseIcon>
                  <span>Add a new address</span>
                </BaseLink>
              </div>

              <div class="px-8 py-4 bg-dawn-lt3">
                <div class="w-52 max-w-full">
                  <BaseButton @click="closeShipping">Continue</BaseButton>
                </div>
              </div>
            </template>
          </div>

          <div v-if="mobile" class="mt-2">
            <BaseLink
              :to="{ name: 'CheckoutShippingAdd' }"
              class="flex items-center px-5 py-4 border border-gray-active hover:border-gray-dark active:border-gray-darker bg-dawn-lt3 text-green hover:text-green-darker active:text-green-darkest font-semibold text-sm transition duration-200"
              data-cy="add-address"
            >
              <BaseIcon :size="4" class="mr-4">
                <IconPlus />
              </BaseIcon>
              <span>Add a new address</span>
            </BaseLink>

            <div class="pt-6">
              <BaseLinkStyled
                :to="backToCartUrl"
                color="black"
                font-size="xs"
                icon-position="left"
                :icon-size="3"
              >
                <template v-slot:icon>
                  <IconArrowLeft />
                </template>
                <template v-slot>Return to cart</template>
              </BaseLinkStyled>
            </div>
          </div>
        </div>

        <div v-if="!isShippingView" class="mt-3 md:mt-0 flex flex-shrink-0 pl-5 md:pl-0">
          <BaseLinkStyled :to="{ name: 'CheckoutShipping' }" font-size="xs">
            Change
          </BaseLinkStyled>
        </div>
      </div>
    </BaseTransitionView>

    <Portal to="modal">
      <BaseModal
        v-if="!mobile"
        size="xs"
        :open="route.name === 'CheckoutShippingEdit' || route.name === 'CheckoutShippingAdd'"
        bg-color="dawn"
        dismissible
        @dismiss="$navigate({ name: 'CheckoutShipping' })"
      >
        <BaseTransitionView no-scroll>
          <CheckoutShippingEdit v-if="route.name === 'CheckoutShippingEdit'" />
          <CheckoutShippingAdd
            v-if="route.name === 'CheckoutShippingAdd'"
            :store-type="storeType"
          />
        </BaseTransitionView>
      </BaseModal>
    </Portal>
  </div>
</template>
