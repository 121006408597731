<script>
  import { get, call } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import AddressForm from '@/components/account/AddressForm'

  export default {
    components: {
      AddressForm,
    },
    data() {
      return {
        formRef: null,
        addressId: parseInt(this.$route.params.addressId),
      }
    },
    computed: {
      ...get('account', ['addressesById']),
      address() {
        return this.addressesById[this.addressId]
      },
    },
    methods: {
      ...call('account', ['updateAddress']),
      async submit(address) {
        try {
          await this.formRef.process(this.updateAddress(address))
          this.$navigate({ name: 'CheckoutShipping' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <AddressForm v-ref="formRef" :edit-address="address" @submit="submit">
      Edit your address
      <template v-slot:buttonLabel>Use this address</template>
    </AddressForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'CheckoutShipping' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
