<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import ShippingModal from '@/components/marketplace/ShippingModal'

  export default {
    components: {
      ShippingModal,
    },
    props: {
      /** What view this is being used for. */
      view: {
        type: String,
        default: undefined,
        validator: (value) => ['cart', 'checkout'].includes(value),
      },
      /** Which market this is being used for. */
      type: {
        type: String,
        required: true,
        validator: (value) => ['essentials', 'marketplace', 'choicePlus', 'upsell'].includes(value),
      },
    },
    data() {
      return {
        shippingModalVisible: false,
      }
    },
    computed: {
      ...get('account', ['baseShipping']),
      ...get('config', ['currentSeason']),
      ...get('couponAndGiftCard', ['submittedCouponCodeIsValid', 'submittedGiftCardIsValid']),
      ...get('marketplaceCart', [
        'hasFreeShipping',
        'remainingAmountForFreeShipping',
        'rushShipping',
      ]),
      screen,
      subtotalLabel() {
        if (this.type === 'choicePlus') {
          return 'Choice+ Items'
        } else {
          return this.view === 'cart'
            ? 'Item Subtotal'
            : `Items (${this.lookupCartProp('cartQuantity')})`
        }
      },
      cart() {
        return this.type === 'upsell' ? this.type : `${this.type}Cart` // marketplaceCart, essentialsCart, choicePlusCart, upsell
      },
      timelineModalSlug() {
        return `${this.currentSeason?.toLowerCase()}-boxes-choice-orders`
      },
    },
    methods: {
      lookupCartProp(prop) {
        return this.$store.get(`${this.cart}/${prop}`) ?? null
      },
    },
  }
</script>

<template>
  <div>
    <BasePriceBreakdown size="sm" :class="type === 'choicePlus' ? 'px-4' : ''">
      <template v-if="type !== 'choicePlus'" v-slot:title>Order Summary</template>
      <BasePriceBreakdownItem
        :label="subtotalLabel"
        :price="lookupCartProp('subtotal')"
        :calculating="lookupCartProp('calculatingLineItems')"
        data-cy="order-subtotal"
      />
      <BasePriceBreakdownItem
        v-if="type !== 'choicePlus' && lookupCartProp('cartSavings') > 0"
        label="Savings"
        :price="lookupCartProp('cartSavings')"
        :calculating="lookupCartProp('calculatingLineItems')"
        class="font-semibold"
      />
      <BasePriceBreakdownItem
        label="Shipping"
        :sublabel="
          type === 'marketplace' &&
          remainingAmountForFreeShipping &&
          !lookupCartProp('calculatingLineItems')
            ? `${$formatPrice(remainingAmountForFreeShipping)} away from free standard shipping!`
            : undefined
        "
        :before-price="type === 'marketplace' && hasFreeShipping ? baseShipping : undefined"
        :price="lookupCartProp('shippingAmount')"
        :calculating="lookupCartProp('calculatingLineItems')"
      >
        <template v-slot:icon>
          <BaseIconClickable
            label="shipping-info"
            :size="3"
            color="black"
            class="-ml-1 -mt-2px focus:shadow-none"
            @click="shippingModalVisible = true"
          >
            <IconInfoAlertOutline />
          </BaseIconClickable>
        </template>
      </BasePriceBreakdownItem>
      <BasePriceBreakdownItem
        v-if="type !== 'choicePlus' && lookupCartProp('ecoUpgrade')"
        label="Eco-Upgrade"
        :price="1"
        :calculating="lookupCartProp('calculatingLineItems')"
      />
      <BasePriceBreakdownItem
        v-if="lookupCartProp('taxAmount') > 0"
        label="Estimated Tax"
        :price="lookupCartProp('taxAmount')"
        :calculating="lookupCartProp('calculatingLineItems')"
      />
      <BasePriceBreakdownItem
        v-if="lookupCartProp('rushShipping')"
        label="Holiday Delivery Fee"
        :calculating="lookupCartProp('calculatingLineItems')"
        :price="lookupCartProp('rushShippingAmount')"
      />
      <BasePriceBreakdownItem
        v-if="
          type !== 'choicePlus' &&
          ((lookupCartProp('discount') < 0 && !lookupCartProp('calculatingLineItems')) ||
            (submittedCouponCodeIsValid && lookupCartProp('calculatingLineItems')))
        "
        label="Discount"
        :price="lookupCartProp('discount')"
        :calculating="lookupCartProp('calculatingLineItems')"
        class="text-secondary-dark"
        data-cy="order-discount"
      />
      <BasePriceBreakdownItem
        v-if="
          (lookupCartProp('credit') > 0 && !lookupCartProp('calculatingLineItems')) ||
          (submittedGiftCardIsValid && lookupCartProp('calculatingLineItems'))
        "
        label="Credit"
        :price="-lookupCartProp('credit')"
        :calculating="lookupCartProp('calculatingLineItems')"
        class="text-secondary-dark"
      />
      <template v-if="type === 'choicePlus'">
        <BaseDivider color="dawn-dk2" class="mt-1 mb-3 -mx-4 hidden lg:block" />
        <BasePriceBreakdownItem
          label="Total"
          :price="lookupCartProp('cartTotal')"
          :calculating="lookupCartProp('calculatingLineItems')"
          class="font-semibold lg:font-heading lg:text-xl"
        />
        <BaseDivider color="dawn-dk2" class="mt-1 mb-3 -mx-4 hidden lg:block" />
      </template>
      <template v-if="type !== 'choicePlus'" v-slot:totalLarge>
        <BasePriceBreakdownItem
          label="Total"
          :price="lookupCartProp('cartTotal')"
          :calculating="lookupCartProp('calculatingLineItems')"
          data-cy="order-total"
        />
      </template>
    </BasePriceBreakdown>
    <!-- Loading spinner -->
    <BaseSpinner
      v-if="
        type !== 'choicePlus' &&
        type !== 'upsell' &&
        (lookupCartProp('calculatingLineItems') || lookupCartProp('placingOrder'))
      "
      overlay="absolute"
      overlay-color="dawn"
      class="z-10"
    />
    <ShippingModal
      :open="shippingModalVisible"
      :type="type === 'marketplace' ? 'seasonal' : type"
      @dismiss="shippingModalVisible = false"
    />
  </div>
</template>
