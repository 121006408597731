import store from '@/store'

export const CONTEXT_ROUTE_ENTER = 'routeEnter'
export const CONTEXT_ROUTE_UPDATE = 'routeUpdate'
export const CONTEXT_PROPERTY_CHANGE = 'propertyChange'

export default (handler, watchedProperties = []) => {
  const createEnterOrUpdateGuard = (context) => {
    return async function (to, from, next) {
      const handlerResult = await handler({
        component: this,
        store,
        context,
        route: to,
        fromRoute: from,
        property: null,
        redirect: (route) => {
          store.set('general/navigationLoading', false)
          next(route)
          return 'handled'
        },
      })
      if (handlerResult !== 'handled') {
        if (handlerResult === false || handlerResult instanceof Error) {
          next(handlerResult)
        } else {
          next(true)
        }
      }
    }
  }

  const handlePropertyChange = async (component, property) => {
    const handlerResult = await handler({
      component,
      store,
      context: CONTEXT_PROPERTY_CHANGE,
      route: store.get('route'),
      fromRoute: null,
      property,
      redirect: (route) => {
        component.$navigate(route)
      },
    })
    if (handlerResult instanceof Error) {
      throw handlerResult
    }
  }

  return {
    beforeRouteEnter: createEnterOrUpdateGuard(CONTEXT_ROUTE_ENTER),
    beforeRouteUpdate: createEnterOrUpdateGuard(CONTEXT_ROUTE_UPDATE),
    watch: Object.fromEntries(
      watchedProperties.map((watchedProperty) => [
        watchedProperty,
        function () {
          handlePropertyChange(this, watchedProperty)
        },
      ])
    ),
  }
}
