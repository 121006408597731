<!-- This componeent reaches out to Prismic for help content that is re-used throughout the site  -->
<script>
  import { get, call } from 'vuex-pathify'

  export default {
    computed: {
      ...get('prismic', ['globalElementsData']),
      helpContent() {
        return this.globalElementsData?.help_content || ''
      },
    },
    created() {
      this.loadGlobalElementsData()
    },
    methods: {
      ...call('prismic', ['loadGlobalElementsData']),
      getName() {
        return this.globalElementsData ? this.globalElementsData.name[0].text : ''
      },
      parseHelpContent(helpContent) {
        if (!helpContent) return ''

        let parsedText = ''
        helpContent.forEach((paragraph) => {
          parsedText += paragraph.text
          paragraph.spans.forEach((span) => {
            const linkText = paragraph.text.substring(span.start, span.end)
            parsedText = parsedText.replace(
              linkText,
              `<a href="${span.data.url}" target="${span.data.target}">${linkText}</a>`
            )
          })
          parsedText += '<br>'
        })

        return parsedText
      },
    },
  }
</script>

<template>
  <div v-if="helpContent">
    <div>
      <BaseIcon :size="3" class="inline-block align-middle mb-3px">
        <IconHelpFilled />
      </BaseIcon>
      <span class="font-semibold">Need help?</span>
    </div>

    <p class="need-help-links">
      <!-- FAQ and Contact us content generated by prismic -->
      <span v-html="parseHelpContent(helpContent)"></span>
    </p>
  </div>
</template>

<style lang="postcss" scoped>
  .need-help-links a {
    text-decoration: underline;
  }
</style>
