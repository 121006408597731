<script>
  import { call } from 'vuex-pathify'

  export default {
    props: {
      /** The type of market the items are being pulled from. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['marketplace', 'choicePlus', 'essentials', 'upsell'].includes(value),
      },
      variants: {
        type: Array,
        default() {
          return []
        },
      },
      hideSoldOut: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        productIdsAddedToWaitlist: [],
      }
    },
    methods: {
      ...call('marketplaceCart', ['addToWaitlist']),
      addProductToWaitlist(variant) {
        this.addToWaitlist({
          productId: variant.productId,
        })
        this.productIdsAddedToWaitlist.push(variant.productId)
      },
      isProductInWaitlist(variant) {
        // TODO: this should check against the marketplace store
        return this.productIdsAddedToWaitlist.includes(variant.productId)
      },
      title(variant) {
        return this.type === 'choicePlus' ? variant.productTitle : variant.title
      },
    },
  }
</script>

<template>
  <ul>
    <li
      v-for="(variant, index) in variants"
      :key="variant.id"
      :class="`${index > 0 ? 'mt-1' : ''} flex items-center p-2 pr-8 bg-gray-lightest`"
    >
      <div class="mr-4 w-14 flex-shrink-0">
        <BaseImage
          :src="variant.previewCardImages[0]"
          :alt="title(variant)"
          :aspect-ratio="1"
          responsive="xs"
        />
      </div>
      <div class="flex-1 py-1 text-gray text-xs">
        <div class="leading-snug"
          >{{ hideSoldOut ? '' : 'Sold out - ' }}{{ variant.brand }} {{ title(variant) }}
        </div>
        <div v-if="type === 'marketplace' && !hideSoldOut" class="mt-1">
          <div v-if="isProductInWaitlist(variant)" class="flex items-center text-green">
            <BaseIcon :size="5" class="-ml-1 mr-2px -mt-2px flex-shrink-0">
              <IconBell />
            </BaseIcon>
            <div>You’re on the waitlist!</div>
          </div>
          <BaseLinkStyled v-else color="gray" @click="addProductToWaitlist(variant)">
            Add to wait list
          </BaseLinkStyled>
        </div>
      </div>
    </li>
  </ul>
</template>
