<script>

  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import HelpMessaging from '../app/HelpMessaging.vue'

  export default {
    components: {
      HelpMessaging,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'seasonal',
        validator: (value) => ['essentials', 'upsell', 'seasonal', 'choicePlus'].includes(value),
      },
    },
    computed: {
      ...get('prismic', ['getShippingTimelineData']),
      screen,
      shippingTimelineData() {
        return this.getShippingTimelineData(this.type)
      },
      header() {
        return this.shippingTimelineData?.header
      },
      title() {
        return this.shippingTimelineData?.title[0].text ?? 'Market'
      },
      copy() {
        return this.shippingTimelineData?.copy
      },
    },
    created() {
      this.loadShippingTimelines()
    },
    methods: {
      ...call('prismic', ['loadShippingTimelines']),
    },
  }
</script>

<template>
  <Portal to="modal">
    <BaseModal
      size="sm"
      height="md"
      :open="open"
      bg-color="dawn"
      hide-nav-on-mobile
      dismissible
      mobile-full-screen
      @dismiss="$emit('dismiss')"
    >
      <div class="font-medium text-2xs mb-5">
        <PrismicRichText v-if="header" :field="header" />
        <span v-else>FULFILLMENT &amp; SHIPPING TIMELINES</span>
      </div>
      <h1 class="font-heading font-medium text-5xl leading-none"> {{ title }} Orders </h1>
      <BaseDivider color="black" class="my-4" />
      <div class="modal-content">
        <PrismicRichText v-if="copy" :field="copy" />
      </div>
      <BaseDivider color="black" class="mt-9 mb-4" />
      <div class="flex flex-col items-center text-xs need-help-links">
        <HelpMessaging />
      </div>
    </BaseModal>
  </Portal>
</template>

<style lang="postcss" scoped>
  .modal-content {
    >>> & {
      p {
        @apply leading-close text-md;
      }

      ul {
        list-style-position: inside;

        li::before {
          @apply pr-3;

          content: '\2192';
        }

        li {
          @apply mb-22px leading-close text-md;
        }
      }
    }
  }
</style>
