<script>
  /**
   * A checkbox component for rush shipping.
   */
  export default {
    props: {
      /** Which market this is being used for. */
      cart: {
        type: String,
        required: true,
        validator: (value) => ['essentialsCart', 'marketplaceCart'].includes(value),
      },
      /** Color of the rush shipping component */
      color: {
        type: String,
        default: 'primary',
        validator: (value) => ['dawn', 'primary'].includes(value),
      },
      /** Size of the rush shipping icon */
      iconSize: {
        type: Number,
        default: 5,
      },
    },
    computed: {
      rushShipping: {
        get: function () {
          return this.$store.get(`${this.cart}/rushShipping`)
        },
        set: function (value) {
          return this.$store.set(`${this.cart}/rushShipping`, value)
        },
      },
    },
    watch: {
      rushShipping() {
        this.reviewOrder()
      },
    },
    methods: {
      reviewOrder() {
        this.$store.dispatch(`${this.cart}/reviewOrder`)
      },
    },
  }
</script>

<template>
  <div class="py-4 flex justify-between items-center">
    <div class="flex items-center">
      <BaseCheckbox
        v-model="rushShipping"
        :color="color"
        label-hidden
        data-cy="rush-shipping-cart-checkbox"
      >
        Holiday Delivery
      </BaseCheckbox>
      <div
        class="flex items-center"
        :class="color === 'dawn' ? 'bg-dawn rounded-full py-1 pr-3 ml-3' : ''"
      >
        <BaseIcon :size="iconSize" class="mt-1 ml-2 mr-1">
          <IconRushShipping />
        </BaseIcon>
        <!-- label next to checkbox -->
        <slot />
      </div>
    </div>
    <div>
      <!-- sublabel of checkbox -->
      <slot name="sublabel" />
    </div>
  </div>
</template>
