<script>
  import { get } from 'vuex-pathify'
  import CheckoutBillingPaymentMethod from '@/views/CheckoutBillingPaymentMethod'
  import CheckoutBillingCreditCard from '@/views/CheckoutBillingCreditCard'
  import screen from '@/helpers/screen'

  export default {
    components: {
      CheckoutBillingPaymentMethod,
      CheckoutBillingCreditCard,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials'].includes(value),
      },
    },
    data() {
      return {
        formRef: null,
        giftCardHelpButtonRef: null,
        giftCardHelpPanelVisible: false,
        isCouponEnabled: true,
        isGiftCardEnabled: true,
      }
    },
    computed: {
      ...get(['route']),
      ...get('account', ['billingInfo']),

      isBillingView() {
        return this.route.name.startsWith('CheckoutBilling')
      },
      isSummaryView() {
        return this.route.name === 'CheckoutSummary'
      },
      paymentSettingsClasses() {
        if (!this.mobile) {
          return 'flex-1'
        } else if (this.isSummaryView) {
          return 'mt-3 pl-5'
        } else {
          return 'p-3 bg-dawn-lt3 border border-gray-active'
        }
      },
      mobile() {
        return !screen().md
      },
      cart() {
        return `${this.storeType}Cart` // marketplaceCart, choicePlusCart, essentialsCart
      },
      calculatingLineItems() {
        return this.$store.get(`${this.cart}/calculatingLineItems`)
      },
      cartPreviewLoaded() {
        return this.$store.get(`${this.cart}/cartPreviewLoaded`)
      },

      couponCode: {
        get: function () {
          return this.$store.get(`${this.cart}/couponCode`)
        },
        set: function (value) {
          return this.$store.set(`${this.cart}/couponCode`, value.toUpperCase())
        },
      },
      giftCardCode: {
        get: function () {
          return this.$store.get(`${this.cart}/giftCardCode`)
        },
        set: function (value) {
          return this.$store.set(`${this.cart}/giftCardCode`, value.toUpperCase())
        },
      },
    },
    created() {
      this.reviewOrder()
    },
    methods: {
      reviewOrder() {
        this.$store.dispatch(`${this.cart}/reviewOrder`)
      },
    },
  }
</script>

<template>
  <div>
    <BaseTransitionView>
      <CheckoutBillingPaymentMethod
        v-if="mobile && route.name === 'CheckoutBillingPaymentMethod'"
      />
      <CheckoutBillingCreditCard v-else-if="mobile && route.name === 'CheckoutBillingCreditCard'" />

      <div v-else :class="`flex ${!mobile ? 'items-baseline' : 'flex-col'}`">
        <div class="md:w-36 lg:w-58 flex-shrink-0">
          <h2 class="text-lg font-semibold">
            <div v-if="!mobile" class="pl-6">
              <span class="absolute left-0 top-0">2</span>
              <span>Payment info</span>
            </div>
            <template v-else>
              <template v-if="isSummaryView">Payment information</template>
              <template v-else>Confirm payment info</template>
            </template>
          </h2>
        </div>

        <div v-if="mobile && isBillingView" class="pt-4 pb-2">
          <BaseButton @click="$navigate({ name: 'CheckoutSummary' })">Continue</BaseButton>
        </div>

        <div :class="paymentSettingsClasses">
          <div :class="`${!mobile ? 'flex flex-wrap justify-between items-baseline' : ''} text-sm`">
            <h4 class="font-semibold">Payment method</h4>
            <BaseBillingInfo v-if="billingInfo" :billing-info="billingInfo" />
            <div v-else>No payment method</div>
            <div class="mt-3 flex">
              <BaseLinkStyled :to="{ name: 'CheckoutBillingPaymentMethod' }" font-size="xs">
                {{ billingInfo ? 'Change' : 'Add' }}
              </BaseLinkStyled>
            </div>
          </div>

          <BaseDivider v-if="isCouponEnabled || isGiftCardEnabled" class="my-6 w-full" />

          <h4 v-if="isCouponEnabled || isGiftCardEnabled" class="mb-2 font-semibold text-sm"
            >Add a gift card or coupon code</h4
          >

          <BaseForm v-if="isCouponEnabled || isGiftCardEnabled" v-ref="formRef">
            <div v-if="isCouponEnabled" class="flex">
              <div class="w-65">
                <BaseInput
                  v-model="couponCode"
                  type="text"
                  name="couponCode"
                  :mask="/^[a-zA-Z0-9-_+]*$/"
                  input-classes="uppercase"
                  data-cy="couponCodeInput"
                  class="discount-fields"
                  @keydown.enter.prevent="$event.target.blur"
                  @blur="reviewOrder"
                >
                  Coupon code
                </BaseInput>
              </div>
              <div class="pl-2 min-w-26">
                <BaseButton
                  outline-only
                  :disabled="calculatingLineItems"
                  data-cy="couponCodeButton"
                  @click="reviewOrder"
                >
                  Apply
                </BaseButton>
              </div>
            </div>

            <div v-if="isGiftCardEnabled" class="flex mt-3">
              <div class="w-65">
                <BaseInput
                  v-model="giftCardCode"
                  type="text"
                  name="giftCardCode"
                  :mask="/^[a-zA-Z0-9-_+]*$/"
                  input-classes="uppercase"
                  class="discount-fields"
                  @keydown.enter.prevent="$event.target.blur"
                  @blur="reviewOrder"
                >
                  Gift card
                  <template v-slot:icons>
                    <BaseIconInput
                      v-ref="giftCardHelpButtonRef"
                      label="Help"
                      @click="giftCardHelpPanelVisible = !giftCardHelpPanelVisible"
                    >
                      <IconHelp />
                    </BaseIconInput>
                  </template>
                </BaseInput>
                <div class="-mb-2">
                  <BaseSlideUpDown :expanded="giftCardHelpPanelVisible">
                    <div class="pt-2 pb-3">
                      <BasePanel :arrow-anchor="giftCardHelpButtonRef">
                        <p class="text-sm">
                          Your payment method will only be used to pay for additional charges not
                          covered by your gift card.
                        </p>
                      </BasePanel>
                    </div>
                  </BaseSlideUpDown>
                </div>
              </div>
              <div class="pl-2 min-w-26">
                <BaseButton outline-only :disabled="calculatingLineItems" @click="reviewOrder">
                  Apply
                </BaseButton>
              </div>
            </div>
          </BaseForm>
        </div>

        <div v-if="mobile && isBillingView" class="mt-8">
          <BaseLinkStyled
            :to="{ name: 'CheckoutShipping' }"
            color="black"
            font-size="xs"
            icon-position="left"
            :icon-size="3"
          >
            <template v-slot:icon>
              <IconArrowLeft />
            </template>
            <template v-slot>Back to shipping</template>
          </BaseLinkStyled>
        </div>
      </div>
    </BaseTransitionView>

    <Portal to="modal">
      <BaseModal
        v-if="!mobile"
        size="xs"
        :open="
          route.name === 'CheckoutBillingPaymentMethod' ||
          route.name === 'CheckoutBillingCreditCard'
        "
        bg-color="dawn"
        dismissible
        @dismiss="$navigate({ name: 'CheckoutBilling' })"
      >
        <BaseTransitionView no-scroll>
          <CheckoutBillingPaymentMethod v-if="route.name === 'CheckoutBillingPaymentMethod'" />
          <CheckoutBillingCreditCard v-if="route.name === 'CheckoutBillingCreditCard'" />
        </BaseTransitionView>
      </BaseModal>
    </Portal>
  </div>
</template>

<style lang="postcss" scoped>
  .discount-fields {
    & >>> .input {
      @apply bg-dawn-lt3;
    }
  }
</style>
