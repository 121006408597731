<script>
  import SoldOutItems from '@/components/checkout/SoldOutItems'
  import screen from '@/helpers/screen'
  import { getRecurlyErrorMessage } from '@/helpers/recurly'

  export default {
    components: {
      SoldOutItems,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials', 'choicePlus', 'upsell'].includes(value),
      },
      errors: {
        type: Object, // json from backend
        required: true,
      },
      isCartEmpty: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
      cart() {
        return this.storeType === 'upsell' ? this.storeType : `${this.storeType}Cart` // marketplaceCart, essentialsCart, choicePlusCart
      },
      hasVariantStockError() {
        return Boolean(this.errors?.errors?.variantStock)
      },
      isOutOfStockError() {
        return this.errors?.type === 'out_of_stock' || Boolean(this.errors?.errors?.soldOut)
      },
      isNotEnoughStockError() {
        return Boolean(this.errors?.errors?.limitedStock)
      },
      isRushShippingStockError() {
        return Boolean(this.errors?.errors?.variantRushStock)
      },
      filteredRushShippingStock() {
        if (this.errors?.errors?.variantRushStock) {
          const { variantRushStock, ...variants } = this.errors?.errors
          return Object.keys(variants).map((variantId) => Number(variantId))
        }
        return {}
      },
      filteredStockErrors() {
        if (this.errors?.errors?.variantStock) {
          const { variantStock, soldOut, ...variants } = this.errors?.errors
          return variants
        }
        return {}
      },
      filteredStockVariantIds() {
        return this.hasVariantStockError ? Object.keys(this.filteredStockErrors) : []
      },
      soldOutLineItems() {
        const variants = this.filteredStockVariantIds
          ?.filter((variantId) => this.errors?.errors?.[variantId] <= 0) // stock is 0
          ?.map((variantId) => Number(variantId))
        return this.$store
          .get(`${this.cart}/lineItems`)
          .filter(({ variant }) => variants.includes(variant.id))
      },
      limitedStockItems() {
        const variants = this.filteredStockVariantIds
          ?.filter((variantId) => this.errors?.errors?.[variantId] > 0) // stock is not 0
          ?.map((variantId) => Number(variantId))
        return this.$store
          .get(`${this.cart}/lineItems`)
          .filter(({ variant }) => variants.includes(variant.id))
      },
      maxQuantityItems() {
        const finalLineItems = this.$store.get(`${this.cart}/finalLineItems`)
        const indexRegex = /[^lineItems.]/
        const index = []
        Object.keys(this.errors?.errors).forEach((key) => {
          index.push(Number(key.match(indexRegex)))
        })
        return finalLineItems.filter((lineItem, lineItemIndex) => index.includes(lineItemIndex))
      },
      isEmptyOrder() {
        return this.errors?.errors?.lineItems === 'Order cannot be empty.'
      },
      isPaymentError() {
        return (
          this.errors?.message.includes('declined') ||
          this.errors?.errors?.description === 'Billing info is required'
        )
      },
      isCouponError() {
        return Boolean(this.errors?.errors?.couponCode)
      },
      isGiftCardError() {
        return Boolean(this.errors?.errors?.giftCardCode)
      },
      isShippingError() {
        return Boolean(this.errors?.errors?.shippingAddressId)
      },
      isMarketClosedError() {
        return this.errors?.errors?.[0]?.includes('is closed')
      },
      isMaxQuantityError() {
        return this.errors?.message === 'Maximum quantity error'
      },
      isMultipleSoldOutItems() {
        return this.soldOutLineItems?.length > 1
      },
      soldOutVariants() {
        if (this.storeType === 'choicePlus') {
          return this.soldOutLineItems?.map(({ variant }) => variant)
        }
        return this.soldOutLineItems?.map(({ product }) => product.defaultVariant)
      },
      limitedItemVariants() {
        if (this.storeType === 'choicePlus') {
          return this.limitedStockItems?.map(({ variant }) => variant)
        }
        return this.limitedStockItems?.map(({ product }) => product.defaultVariant)
      },
      maxQuantityVariants() {
        if (this.storeType === 'choicePlus') {
          return this.maxQuantityItems?.map(({ variant }) => variant)
        }
        return this.maxQuantityItems?.map(({ product }) => product.defaultVariant)
      },
      backToCartUrl() {
        // views start with a capital letter, stores with lowercase
        if (this.storeType === 'choicePlus') {
          return 'CustomizeSummary'
        } else if (this.storeType === 'upsell') {
          return 'SubscribeMarketCheckout'
        }
        return `${this.cart.charAt(0).toUpperCase() + this.cart.slice(1)}`
      },
      recurlyErrorMessage() {
        return getRecurlyErrorMessage(this.errors)
      },
    },
    methods: {
      async adjustQtys() {
        if (this.isMaxQuantityError) {
          const updatedVariantStock = {}
          this.maxQuantityVariants.forEach((variant) => {
            updatedVariantStock[variant.id] = variant.maxQuantity
          })
          await this.$store.dispatch(`${this.cart}/adjustQtys`, updatedVariantStock)
        } else {
          await this.$store.dispatch(`${this.cart}/adjustQtys`, this.filteredStockErrors)
        }
        this.$emit('clearErrors')
      },
      async placeOrder() {
        await this.adjustQtys()
        this.$emit('placeOrder')
      },
      async goBackToCart() {
        await this.adjustQtys()
        this.$navigate({ name: this.backToCartUrl })
      },
      dismissHolidayError() {
        this.$store.dispatch(`${this.cart}/adjustRushShippingStock`, {
          variantIds: this.filteredRushShippingStock,
        })
        this.$emit('clearErrors')
        if (!this.$route.name.includes('Cart')) {
          this.$navigate({ name: this.backToCartUrl })
        }
      },
      dismissAndReviewOrder() {
        this.$emit('clearErrors')
        this.$store.dispatch(`${this.cart}/reviewOrder`)
      },
    },
  }
</script>

<template>
  <!----------------------------- Not enough rush shipping stock error ----------------------------->
  <BaseModal
    v-if="isRushShippingStockError"
    :open="isRushShippingStockError"
    bg-color="dawn"
    size="xs-sm"
    @dismiss="dismissHolidayError"
  >
    <div class="text-center">
      <p class="font-medium text-xxl md:text-2xl leading-snug md:px-2">
        Oh no! <br />
        Holiday Delivery for an item(s) is no longer available.
      </p>
      <p class="text-md leading-tight mt-2 mb-6">
        Item(s) may be available for Standard Delivery. Please review your order, then continue
        <br v-if="screen.md" />
        to checkout.
      </p>
      <BaseButton class="md:max-w-53 mx-auto" @click="dismissHolidayError">
        Got it, return to cart
      </BaseButton>
    </div>
  </BaseModal>

  <!----------------------------- Sold out error ----------------------------->
  <BaseModalError v-else-if="isOutOfStockError" :open="isOutOfStockError" @dismiss="adjustQtys">
    <template v-slot:header>Sold out item{{ isMultipleSoldOutItems ? 's' : '' }}</template>
    <div v-if="isCartEmpty">Looks like the items in your order just sold out!</div>
    <div v-else>
      Looks like {{ isMultipleSoldOutItems ? 'these items are' : 'this item just' }} sold out!
    </div>
    <div class="mt-4">
      <SoldOutItems :type="storeType" :variants="soldOutVariants" />
    </div>
    <template v-slot:cta>
      <BaseButton @click="goBackToCart">Got it, return to cart</BaseButton>
    </template>
  </BaseModalError>

  <!----------------------------- Not enough stock error ----------------------------->
  <BaseModalError
    v-else-if="isNotEnoughStockError"
    :open="isNotEnoughStockError"
    @dismiss="adjustQtys"
  >
    <template v-slot:header>Not enough stock</template>
    <div> The items below have been updated. </div>
    <div class="mt-4">
      <SoldOutItems :type="storeType" :variants="limitedItemVariants" hide-sold-out />
    </div>
    <template v-slot:cta>
      <BaseButton @click="goBackToCart">Got it, return to cart</BaseButton>
    </template>
  </BaseModalError>

  <!----------------------------- Max quantity error ----------------------------->
  <BaseModalError v-else-if="isMaxQuantityError" :open="isMaxQuantityError" @dismiss="adjustQtys">
    <template v-slot:header>Max quantity reached</template>
    <div> The items below have been updated. </div>
    <div class="mt-4">
      <SoldOutItems :type="storeType" :variants="maxQuantityVariants" hide-sold-out />
    </div>
    <template v-slot:cta>
      <BaseButton @click="goBackToCart">Got it, return to cart</BaseButton>
    </template>
  </BaseModalError>

  <!----------------------------- Empty cart error ----------------------------->
  <BaseModalError v-else-if="isEmptyOrder" :open="isEmptyOrder" @dismiss="$emit('clearErrors')">
    <template v-slot:header>Empty cart</template>
    Looks like your cart is empty!
    <template v-slot:actionTitle>Continue shopping to add items to your cart.</template>
    <template v-slot:cta>
      <BaseButton @click="goBackToCart">Back to cart</BaseButton>
    </template>
  </BaseModalError>

  <!----------------------------- Payment error ----------------------------->
  <BaseModalError
    v-else-if="isPaymentError"
    :open="isPaymentError"
    icon-color="primary-dark"
    @dismiss="$emit('clearErrors')"
  >
    <template v-slot:header>Transaction declined</template>
    Please try making the purchase again with a different card or payment method. If you're still
    having issues, contact your bank to see if there's a problem with your account.
  </BaseModalError>

  <!----------------------------- Recurly Payment error ----------------------------->
  <BaseModalError
    v-else-if="Boolean(recurlyErrorMessage)"
    :open="Boolean(recurlyErrorMessage)"
    icon-color="primary-dark"
    @dismiss="$emit('clearErrors')"
  >
    <template v-slot:header>Transaction declined</template>
    {{ recurlyErrorMessage }} <br />
    <BaseLinkStyled :to="{ name: 'AccountProfileSubscriptions' }" inline underlined
      >Review and update your card info</BaseLinkStyled
    >
  </BaseModalError>

  <!----------------------------- Coupon error -------------------------------->
  <BaseModalError
    v-else-if="isCouponError"
    :open="isCouponError"
    icon-color="primary-dark"
    @dismiss="dismissAndReviewOrder"
  >
    This coupon code is not valid.
  </BaseModalError>

  <!----------------------------- Gift Card error ----------------------------->
  <BaseModalError
    v-else-if="isGiftCardError"
    :open="isGiftCardError"
    icon-color="primary-dark"
    @dismiss="dismissAndReviewOrder"
  >
    This gift card code is not valid.
  </BaseModalError>

  <!----------------------------- Shipping error ----------------------------->
  <BaseModalError
    v-else-if="isShippingError"
    :open="isShippingError"
    icon-color="primary-dark"
    @dismiss="$emit('clearErrors')"
  >
    The associated shipping address for this order is not valid. Please go to the account settings
    page to make any necessary changes.
  </BaseModalError>

  <!----------------------------- Market closed error ----------------------------->
  <BaseModalError
    v-else-if="isMarketClosedError"
    :open="true"
    icon-color="primary-dark"
    @dismiss="$emit('clearErrors')"
  >
    Sorry, the market has ended
  </BaseModalError>

  <!----------------------------- Generic error ----------------------------->
  <BaseModalError v-else :open="true" icon-color="primary-dark" @dismiss="$emit('clearErrors')">
    Something went wrong. Please refresh the page and try again.
  </BaseModalError>
</template>
