<script>
  import { get, call } from 'vuex-pathify'
  import RushShipping from '@/components/checkout/RushShipping'
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'

  export default {
    components: {
      RushShipping,
      HomeWidgetSingleEntryModal,
    },
    props: {
      /** Which market this is being used for. */
      cart: {
        type: String,
        required: true,
        validator: (value) => ['marketplaceCart', 'essentialsCart', 'upsell'].includes(value),
      },
    },
    data() {
      return {
        // growAheadPanelVisible: false,
        // ecoUpgradeAnimationVisible: false,
        // ecoUpgradeAnimationTimeout: null,
        trendingQuestionsModalVisible: false,
      }
    },
    computed: {
      ...get('feature', ['isRushShippingEnabled']),
      ...get('prismic', ['getOrderSummaryData']),
      orderSummaryCopy() {
        return this.getOrderSummaryData(this.cart)
      },
      cartCanAddRushShipping() {
        return this.$store.get(`${this.cart}/cartCanAddRushShipping`)
      },
      // ...call/get do not work here
      canPlaceOrder() {
        return this.$store.get(`${this.cart}/canPlaceOrder`)
      },
      canAddOrderAddOn() {
        return this.$store.get(`${this.cart}/canAddOrderAddOn`)
      },
      // ecoUpgrade: {
      //   get() {
      //     return this.$store.get(`${this.cart}/ecoUpgrade`)
      //   },
      //   set(value) {
      //     if (value) {
      //       clearTimeout(this.ecoUpgradeAnimationTimeout)
      //       this.ecoUpgradeAnimationVisible = true
      //       this.ecoUpgradeAnimationTimeout = setTimeout(() => {
      //         this.ecoUpgradeAnimationVisible = false
      //       }, 1100)
      //     } else {
      //       this.ecoUpgradeAnimationVisible = false
      //     }

      //     return this.$store.set(`${this.cart}/ecoUpgrade`, value)
      //   },
      // },

      rushShipping: {
        get() {
          return this.$store.get(`${this.cart}/rushShipping`)
        },
        set(value) {
          return this.$store.set(`${this.cart}/rushShipping`, value)
        },
      },
      rushShippingAmount() {
        return this.$store.get(`${this.cart}/rushShippingAmount`)
          ? this.$store.get(`${this.cart}/rushShippingAmount`) // on, actual adjustment dollars
          : this.$store.get(`${this.cart}/rushShippingPrice`) // off, estimated adjustment dollars
      },
      rushShippingPrice() {
        return this.$store.get(`${this.cart}/rushShippingPrice`)
      },
    },
    watch: {
      ecoUpgrade() {
        this.reviewOrder()
      },
      rushShipping() {
        this.reviewOrder()
      },
    },
    created() {
      this.loadOrderSummaryData()
    },
    methods: {
      ...call('prismic', ['loadOrderSummaryData']),
      reviewOrder() {
        this.$store.dispatch(`${this.cart}/reviewOrder`)
      },
    },
  }
</script>

<template>
  <section class="p-4 rounded-sm bg-dawn">
    <div class="text-center">
      <BaseRichText font-size="2xs" class="text-center">
        <p>
          By clicking “Place your order” you are agreeing to the Alltrue
          <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank" inline>
            Terms of Use
          </BaseLinkStyled>
          and
          <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank" inline>
            Privacy Policy.
          </BaseLinkStyled>
        </p>
      </BaseRichText>
    </div>
    <div class="mt-4">
      <BaseButton :disabled="!canPlaceOrder" @click="$emit('placeOrder')">
        Place your order
      </BaseButton>
    </div>
    <BaseDivider class="my-5" />

    <!-- order summary goes here -->
    <slot />

    <!-- <div class="mt-4">
      <BaseCheckbox v-model="ecoUpgrade" :disabled="!canAddOrderAddOn">
        <template v-slot:box>
          <BaseAnimationClap v-if="ecoUpgradeAnimationVisible" class="transform scale-240" />
        </template>
        <p class="text-xs -mt-1">
          Add $1 to your order for Eco-Friendly shipping to help mitigate related carbon emissions
          via a contribution to our partner,
          <BaseLinkStyled inline @click="growAheadPanelVisible = !growAheadPanelVisible"
            >Grow Ahead</BaseLinkStyled
          >
        </p>
      </BaseCheckbox>
    </div> -->
    <RushShipping v-if="cartCanAddRushShipping" :cart="cart">
      <BaseDivider class="mt-2" />
      <span class="text-xs">
        Holiday Delivery Fee - {{ $formatPrice(rushShippingPrice, false) }}</span
      >
      <span>&nbsp;</span>
      <BaseIcon
        type="button"
        label="learn more"
        :size="3"
        class="inline-block align-middle"
        @click="trendingQuestionsModalVisible = true"
      >
        <IconInfoAlertOutline />
      </BaseIcon>
    </RushShipping>
    <!-- <BaseSlideUpDown :expanded="growAheadPanelVisible">
      <div class="ml-5 pt-4">
        <BasePanel color="green" dismissible @dismiss="growAheadPanelVisible = false">
          <div class="py-1">
            <BaseRichText font-size="xs">
              <p>
                Let’s build a greener, brighter future together! At Alltrue we’re committed to doing
                our part in the fight against climate change. That’s why we’ve partnered with Grow
                Ahead, a 501(c)(3) non-profit organization that supports small-scale farmers using
                agroforestry to combat climate change and support local communities. Your $1
                eco-friendly upgrade will be passed on as a direct donation to Grow Ahead to
                distribute, manage, monitor, and evaluate tree planting in farm communities
                worldwide, and effectively make the shipment of your order carbon neutral.
              </p>
              <p> $1 = 1 tree planted. </p>
            </BaseRichText>
          </div>
        </BasePanel>
      </div>
    </BaseSlideUpDown> -->
    <BaseDivider class="my-3" />
    <div class="flex flex-col justify-center items-center">
      <BaseRichText font-size="2xs">
        <PrismicRichText v-if="orderSummaryCopy" :field="orderSummaryCopy" />
      </BaseRichText>
      <BaseDivider class="my-3 w-full" />
      <div class="flex text-gray">
        <BaseIcon type="icon" :size="4" class="mr-1 flex-shrink-0">
          <IconLock />
        </BaseIcon>
        <span class="text-xs">Secure Transaction</span>
      </div>
    </div>

    <!-- Rush Shipping Modals -->
    <HomeWidgetSingleEntryModal
      v-if="isRushShippingEnabled"
      :open="trendingQuestionsModalVisible"
      :update="{ type: 'trending', slug: 'holiday-delivery' }"
      override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
      @dismiss="trendingQuestionsModalVisible = false"
    />
  </section>
</template>
