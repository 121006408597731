var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasePriceBreakdown',{class:_vm.type === 'choicePlus' ? 'px-4' : '',attrs:{"size":"sm"},scopedSlots:_vm._u([(_vm.type !== 'choicePlus')?{key:"title",fn:function(){return [_vm._v("Order Summary")]},proxy:true}:null,(_vm.type !== 'choicePlus')?{key:"totalLarge",fn:function(){return [_c('BasePriceBreakdownItem',{attrs:{"label":"Total","price":_vm.lookupCartProp('cartTotal'),"calculating":_vm.lookupCartProp('calculatingLineItems'),"data-cy":"order-total"}})]},proxy:true}:null],null,true)},[_vm._v(" "),_c('BasePriceBreakdownItem',{attrs:{"label":_vm.subtotalLabel,"price":_vm.lookupCartProp('subtotal'),"calculating":_vm.lookupCartProp('calculatingLineItems'),"data-cy":"order-subtotal"}}),_vm._v(" "),(_vm.type !== 'choicePlus' && _vm.lookupCartProp('cartSavings') > 0)?_c('BasePriceBreakdownItem',{staticClass:"font-semibold",attrs:{"label":"Savings","price":_vm.lookupCartProp('cartSavings'),"calculating":_vm.lookupCartProp('calculatingLineItems')}}):_vm._e(),_vm._v(" "),_c('BasePriceBreakdownItem',{attrs:{"label":"Shipping","sublabel":_vm.type === 'marketplace' &&
        _vm.remainingAmountForFreeShipping &&
        !_vm.lookupCartProp('calculatingLineItems')
          ? ((_vm.$formatPrice(_vm.remainingAmountForFreeShipping)) + " away from free standard shipping!")
          : undefined,"before-price":_vm.type === 'marketplace' && _vm.hasFreeShipping ? _vm.baseShipping : undefined,"price":_vm.lookupCartProp('shippingAmount'),"calculating":_vm.lookupCartProp('calculatingLineItems')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIconClickable',{staticClass:"-ml-1 -mt-2px focus:shadow-none",attrs:{"label":"shipping-info","size":3,"color":"black"},on:{"click":function($event){_vm.shippingModalVisible = true}}},[_c('IconInfoAlertOutline')],1)]},proxy:true}])}),_vm._v(" "),(_vm.type !== 'choicePlus' && _vm.lookupCartProp('ecoUpgrade'))?_c('BasePriceBreakdownItem',{attrs:{"label":"Eco-Upgrade","price":1,"calculating":_vm.lookupCartProp('calculatingLineItems')}}):_vm._e(),_vm._v(" "),(_vm.lookupCartProp('taxAmount') > 0)?_c('BasePriceBreakdownItem',{attrs:{"label":"Estimated Tax","price":_vm.lookupCartProp('taxAmount'),"calculating":_vm.lookupCartProp('calculatingLineItems')}}):_vm._e(),_vm._v(" "),(_vm.lookupCartProp('rushShipping'))?_c('BasePriceBreakdownItem',{attrs:{"label":"Holiday Delivery Fee","calculating":_vm.lookupCartProp('calculatingLineItems'),"price":_vm.lookupCartProp('rushShippingAmount')}}):_vm._e(),_vm._v(" "),(
        _vm.type !== 'choicePlus' &&
        ((_vm.lookupCartProp('discount') < 0 && !_vm.lookupCartProp('calculatingLineItems')) ||
          (_vm.submittedCouponCodeIsValid && _vm.lookupCartProp('calculatingLineItems')))
      )?_c('BasePriceBreakdownItem',{staticClass:"text-secondary-dark",attrs:{"label":"Discount","price":_vm.lookupCartProp('discount'),"calculating":_vm.lookupCartProp('calculatingLineItems'),"data-cy":"order-discount"}}):_vm._e(),_vm._v(" "),(
        (_vm.lookupCartProp('credit') > 0 && !_vm.lookupCartProp('calculatingLineItems')) ||
        (_vm.submittedGiftCardIsValid && _vm.lookupCartProp('calculatingLineItems'))
      )?_c('BasePriceBreakdownItem',{staticClass:"text-secondary-dark",attrs:{"label":"Credit","price":-_vm.lookupCartProp('credit'),"calculating":_vm.lookupCartProp('calculatingLineItems')}}):_vm._e(),_vm._v(" "),(_vm.type === 'choicePlus')?[_c('BaseDivider',{staticClass:"mt-1 mb-3 -mx-4 hidden lg:block",attrs:{"color":"dawn-dk2"}}),_vm._v(" "),_c('BasePriceBreakdownItem',{staticClass:"font-semibold lg:font-heading lg:text-xl",attrs:{"label":"Total","price":_vm.lookupCartProp('cartTotal'),"calculating":_vm.lookupCartProp('calculatingLineItems')}}),_vm._v(" "),_c('BaseDivider',{staticClass:"mt-1 mb-3 -mx-4 hidden lg:block",attrs:{"color":"dawn-dk2"}})]:_vm._e()],2),_vm._v(" "),(
      _vm.type !== 'choicePlus' &&
      _vm.type !== 'upsell' &&
      (_vm.lookupCartProp('calculatingLineItems') || _vm.lookupCartProp('placingOrder'))
    )?_c('BaseSpinner',{staticClass:"z-10",attrs:{"overlay":"absolute","overlay-color":"dawn"}}):_vm._e(),_vm._v(" "),_c('ShippingModal',{attrs:{"open":_vm.shippingModalVisible,"type":_vm.type === 'marketplace' ? 'seasonal' : _vm.type},on:{"dismiss":function($event){_vm.shippingModalVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }